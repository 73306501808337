import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useNavigate} from "react-router-dom";
import ApiService from "../../services/ApiService";
import {Alert, AlertTitle, Avatar, Grid, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import FullScreenLoading from "../util/FullScreenLoading";
import {useAccount, useDisconnect, useSignMessage} from "wagmi";
import ConnectWallet from "./ConnectWallet";
import {Link} from '@imtbl/imx-link-sdk'
import ImmutableXLogo from "../../images/ImmutableXLogo.svg";

const apiService = ApiService()
const MAX_LENGTH_NAME = 25

export default function MinterStepper({id, chain}) {

    const navigate = useNavigate();
    const { disconnect } = useDisconnect()

    const { data, error, signMessage } = useSignMessage({
        onSuccess(data, variables) {
            handleNext()
        }
    })

    const { address, isConnected } = useAccount()

    const [activeStep, setActiveStep] = React.useState(0);
    const [name, setName] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingPage, setIsLoadingPage] = useState(true)
    const [errorMessage, setErrorMessage] = useState("")
    const [hint, setHint] = useState("")
    const [mintingError, setMintingError] = useState("")
    const [isRegisteringImmutable, setIsRegisteringImmutable] = useState(false)

    const handleNext = () => {
        setHint("")
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setHint("")
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // const handleReset = () => {
    //     setActiveStep(0);
    // };

    useEffect(() => {
        if (isConnected) {
            setActiveStep(1)
            setIsLoadingPage(false)
        } else {
            setActiveStep(0)
            setIsLoadingPage(false)
        }

    }, [isConnected])

    async function forwardIfTheyAlreadyHaveBadge() {
        // if (web3Service.isOnTheCorrectChain()) {
        //     const tokenIds = await web3Service.fetchTokenIds(await web3Service.getCurrentAccount())
        //     if (tokenIds.length > 0) {
        //         navigate("/view/" + tokenIds[tokenIds.length - 1])
        //     }
        // }
    }

    async function sendMintRequest() {
        setIsLoading(true)
        setMintingError("")

        try {
            const mintData = await apiService.mintToken(id, name, data, address)
            navigate("/" + id + "/view/" + mintData.tokenId)
            setIsLoading(false)
        } catch (e) {
            console.error(e)
            setIsLoading(false)
            if (e.response.status === 401) {
                setMintingError("You are not on the allowlist and do not have permission to mint.")
            } else if (e.response.status === 503) {
                setMintingError("Bad gateway")
            } else {
                setMintingError("Unfortunately there was an issue and we could not mint your badge. Please try again in 1 minute.")
            }
        }
    }

    if (isLoadingPage) {
        return <FullScreenLoading/>
    }

    // <Typography><a target={"_blank"} href={"https://www.youtube.com/watch?v=6_cMqz_nFs8"}>Don't have a wallet? Check out this video for details on how to install MetaMask</a></Typography>

    function renderError() {
        if (errorMessage) {
            return (
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {errorMessage}
                </Alert>
            )
        }
        return ""
    }

    async function runImmutableSetup() {
        setIsRegisteringImmutable(true)
        try {
            const registeredData = await apiService.isRegistered(address)
            if (registeredData.accounts) {
                handleNext()
            } else {
                await registerImmutableUser()
            }
        } catch (e) {
            await registerImmutableUser()
        }
        setIsRegisteringImmutable(false)
    }

    async function registerImmutableUser() {
        try {
            const link = new Link(process.env.REACT_APP_IMMUTABLE_X_LINK_URL)
            const result = await link.setup({})
            handleNext()
            console.log(result)
        } catch (e) {

        }
    }

    function renderFirstStep() {

        if(chain === "immutable") {


            return (
                <Step>
                    <StepLabel>
                        <strong>Register with Immutable</strong>
                    </StepLabel>
                    <StepContent>
                        <Typography>In this step we connect your wallet to our website so you can sign your certificate.</Typography>
                        <Box sx={{ mb: 2 }}>
                            <div>
                                <Button
                                    color={"inherit"}
                                    style={{textTransform: "none"}}
                                    variant={"outlined"} target={"_blank"}
                                    endIcon={<Avatar sx={{ width: 24, height: 24 }} src={ImmutableXLogo} color={"info"}/>}
                                    onClick={runImmutableSetup}
                                    sx={{ mt: 1, mr: 1 }}
                                    disabled={isRegisteringImmutable}
                                >Register</Button>
                            </div>
                        </Box>
                    </StepContent>
                </Step>
            )
        }

    }

    return (
        <Grid container alignItems={"center"} justifyContent={"center"}>
            <Grid item xs={12}>
                <Grid container alignItems={"center"} justifyContent={"center"} style={{paddingTop: 56}}>
                    <Grid item xs={12}>
                        {renderError()}
                        {/*{isConnected ?*/}
                        {/*    <Alert severity="info">*/}
                        {/*        <AlertTitle>Connected Wallet</AlertTitle>*/}
                        {/*        {address}*/}
                        {/*    </Alert>*/}
                        {/*    : ''*/}
                        {/*}*/}
                        <Box sx={{ maxWidth: 400, textAlign: "left" }}>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                <Step>
                                    <StepLabel>
                                        {/*<strong>Connect your wallet</strong>*/}
                                        {isConnected ? <span><strong>Connected to </strong> {address}<Button style={{textTransform: "none", marginTop: 5}} variant={"outlined"} onClick={disconnect}>Disconnect</Button></span> : <strong>Connect your wallet</strong>}
                                    </StepLabel>
                                    <StepContent>
                                        <Typography>In this step we connect your wallet to our website so you can sign your certificate.</Typography>
                                        <Typography><strong>{hint}</strong></Typography>
                                        <ConnectWallet/>
                                    </StepContent>
                                </Step>

                                {renderFirstStep()}

                                <Step>
                                    <StepLabel>
                                        <strong>Sign your name</strong>
                                    </StepLabel>
                                    <StepContent>
                                        <Typography>Enter your name as you want it to appear on your badge.</Typography>
                                        <TextField id="outlined-basic" label="Your Name" variant="outlined" value={name}
                                                   style={{marginTop: 24}} inputProps={{ maxLength: MAX_LENGTH_NAME }}
                                                   onChange={e => setName(e.target.value)}
                                                   helperText={name.length + ' of ' + MAX_LENGTH_NAME}
                                        />
                                        <Box sx={{ mb: 2 }}>
                                            <div>
                                                <Button
                                                    disabled={!name || !name.trim()}
                                                    variant="contained"
                                                    onClick={() => signMessage({message: name})}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    Next
                                                </Button>
                                            </div>
                                        </Box>
                                    </StepContent>
                                </Step>

                                <Step>
                                    <StepLabel
                                        optional={<Typography variant="caption">Last step</Typography>}
                                    >
                                        <strong>Mint your NFT</strong>
                                    </StepLabel>
                                    <StepContent>
                                        <Typography>This may take a couple of minutes please be patient!</Typography>
                                        <Typography color={"red"}><strong>{mintingError}</strong></Typography>
                                        <Box sx={{ mb: 2 }}>
                                            <div>
                                                <LoadingButton
                                                    variant="contained"
                                                    loading={isLoading}
                                                    onClick={sendMintRequest}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    Create
                                                </LoadingButton>
                                                <Button
                                                    onClick={handleBack}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    Back
                                                </Button>
                                            </div>
                                        </Box>
                                        {isLoading ? <Typography><small>Minting your token...</small></Typography> : ''}
                                    </StepContent>
                                </Step>
                            </Stepper>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
