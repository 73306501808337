import * as React from 'react'
import {Route, Routes} from "react-router-dom";
import {Container} from "@mui/material";
import TenantContainer from "./TenantContainer";

function App() {
    return (
        <Container>
            <Routes>
                <Route path="/:id/*" element={<TenantContainer/>}/>
            </Routes>
        </Container>
    );
}

export default App;
