import * as React from 'react'
import MinterStepper from "./MinterStepper";
import Typography from "@mui/material/Typography";
import {Hidden} from "@mui/material";
import OpenSeaButton from "../util/OpenSeaButton";

function MinterPage({id, logoUrl, splashImageUrl, openSeaCollectionSlug, chain}) {

    const openSeaUrl = process.env.REACT_APP_OPENSEA_COLLECTION_URL + "/" + openSeaCollectionSlug

    return (
        <React.Fragment>
            <Hidden mdUp>
                <div style={{width: "100%", height: "100vh", marginBottom: -8, marginTop: -8, display: "flex"}}>
                    <div style={{width: "100%", height: "100%"}}>
                        <div style={{top: "10%", position: "sticky"}}>
                            <div style={{maxWidth: 400}}>
                                <Typography variant={"h5"}>Congratulations on completing the module!</Typography>
                                <Typography style={{marginTop: 16}}>Now let's mint your certificate</Typography>
                            </div>
                            <MinterStepper id={id} chain={chain}/>
                            <div style={{marginTop: 64, paddingBottom: 16}}>
                                {openSeaCollectionSlug && openSeaCollectionSlug.length ? <OpenSeaButton url={openSeaUrl} prompt={"View collection"} /> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </Hidden>
            <Hidden mdDown>

                <div style={{width: "100%", height: "100vh", marginBottom: -8, marginTop: -8, display: "flex"}}>
                    <div style={{width: "50%", height: "100%"}}>
                        <div style={{top: "10%", position: "sticky"}}>
                            <div style={{maxWidth: 400}}>
                                <Typography variant={"h5"}>Congratulations on completing the module!</Typography>
                                <Typography style={{marginTop: 16}}>Now let's mint your certificate</Typography>
                            </div>
                            <MinterStepper id={id} chain={chain}/>
                            <div style={{marginTop: 64, paddingBottom: 16}}>
                                {openSeaCollectionSlug && openSeaCollectionSlug.length ? <OpenSeaButton url={openSeaUrl} prompt={"View collection"} /> : ''}
                            </div>
                        </div>
                    </div>
                    <div style={{width: "50%", height: "100%", backgroundImage: "url(" + splashImageUrl + ")", backgroundPosition: "center", backgroundSize: "cover", position: "absolute", right: 0}}>
                        {/*<div style={{margin: "auto", width: 500, top: "15%", position: "sticky"}}>*/}
                        {/*    <img src={logoFirst} alt={"Logo"}/>*/}
                        {/*</div>*/}
                    </div>
                </div>

            </Hidden>
        </React.Fragment>
    )
}

export default MinterPage;
