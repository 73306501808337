import * as React from 'react'
import {configureChains, createClient, defaultChains, WagmiConfig} from 'wagmi'
import {alchemyProvider} from 'wagmi/providers/alchemy'
import {publicProvider} from 'wagmi/providers/public'

import {CoinbaseWalletConnector} from 'wagmi/connectors/coinbaseWallet'
import {InjectedConnector} from 'wagmi/connectors/injected'
import {MetaMaskConnector} from 'wagmi/connectors/metaMask'
import {WalletConnectConnector} from 'wagmi/connectors/walletConnect'

// Configure chains & providers with the Alchemy provider.
// Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
const { chains, provider, webSocketProvider } = configureChains(defaultChains, [
    alchemyProvider({ apiKey: 'oR0schdHapbIQfWL7y96gqzDvqrVtykp' }),
    publicProvider(),
])

function WalletConfig({tenantChain, children}) {

    const client = createClient({
        autoConnect: true,
        connectors: [
            new MetaMaskConnector({ chains }),
        ],
        provider,
        webSocketProvider,
    })

    if (tenantChain !== "immutable") {
        client.connectors.push(
            new CoinbaseWalletConnector({
                chains,
                options: {
                    appName: 'wagmi',
                },
            }),
            new WalletConnectConnector({
                chains,
                options: {
                    qrcode: true,
                },
            }),
            new InjectedConnector({
                chains,
                options: {
                    name: 'Injected',
                    shimDisconnect: true,
                },
            })
        )
    }

    return (
        <WagmiConfig client={client}>
            {children}
        </WagmiConfig>
    );
}

export default WalletConfig;
