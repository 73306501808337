import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL
const IMMUTABLE_API_BASE_URL = process.env.REACT_APP_IMMUTABLE_X_API_URL

const ApiService = () => {

    async function fetchTenantDetails(id, tokenId) {
        const axiosResponse = await axios.get(BASE_URL + "/" + id)
        return axiosResponse.data
    }

    async function mintToken(id, name, signature, to) {
        const axiosResponse = await axios.post(BASE_URL + "/mint/" + id, {
            name: name,
            signature: signature,
            to: to
        })

        return axiosResponse.data
    }

    async function fetchTokenUri(id, tokenId) {
        const axiosResponse = await axios.get(BASE_URL + "/token/" + id + "/" + tokenId)
        return axiosResponse.data
    }

    async function fetchTokenMetadata(id, tokenId) {
        const axiosResponse = await axios.get(BASE_URL + "/metadata/" + id + "/" + tokenId)
        return axiosResponse.data
    }

    async function fetchMintEvent(id, fromBlock, account) {
        const axiosResponse = await axios.get(BASE_URL + "/event/" + id + "?fromBlock=" + fromBlock + "&account=" + account)
        return axiosResponse.data
    }

    async function isRegistered(userId) {
        const axiosResponse = await axios.get(IMMUTABLE_API_BASE_URL + "/v1/users/" + userId)
        return axiosResponse.data
    }

    return {
        fetchTenantDetails,
        fetchTokenMetadata,
        mintToken,
        fetchTokenUri,
        fetchMintEvent,
        isRegistered
    }
}

export default ApiService
