import {Route, Routes, useParams} from "react-router-dom";
import Viewer from "./viewer/Viewer";
import MinterPage from "./minting/MinterPage";
import {useEffect, useState} from "react";
import FullScreenLoading from "./util/FullScreenLoading";
import ApiService from "../services/ApiService";
import WalletConfig from "./minting/WalletConfig";

const apiService = ApiService()

function TenantContainer() {

    const {id} = useParams()

    const [isLoading, setIsLoading] = useState(true)
    const [tenantDetails, setTenantDetails] = useState({})

    useEffect(() => {
        apiService.fetchTenantDetails(id).then((tenantDetails) => {
            setTenantDetails(tenantDetails)
            setIsLoading(false)
        })
    }, [id])

    if (isLoading) {
        return <FullScreenLoading/>
    }

    return (
        <WalletConfig tenantChain={tenantDetails.chain}>
            <Routes>
                <Route path="/" element={<MinterPage id={id} logoUrl={tenantDetails.logoUrl} splashImageUrl={tenantDetails.splashImageUrl} openSeaCollectionSlug={tenantDetails.openSeaCollectionSlug} chain={tenantDetails.chain}/>}/>
                <Route path="view/:tokenId" element={<Viewer id={id} contractAddress={tenantDetails.contractAddress} collectionName={tenantDetails.collectionName} chain={tenantDetails.chain}/>}/>
            </Routes>
        </WalletConfig>
    );
}

export default TenantContainer;
