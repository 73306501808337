import {Avatar, Button} from "@mui/material";
import ImmutableXLogo from "../../images/ImmutableXLogo.svg";

const ImmutableXButton = ({prompt, url}) => {

    return (
        <Button
            color={"inherit"}
            style={{textTransform: "none"}}
            variant={"outlined"} target={"_blank"}
            endIcon={<Avatar sx={{ width: 24, height: 24 }} src={ImmutableXLogo} color={"info"}/>}
            href={url}
        >{prompt}</Button>
    )
}

export default ImmutableXButton
