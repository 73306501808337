import * as React from 'react'
import {useAccount, useConnect, useDisconnect, useEnsAvatar, useEnsName} from 'wagmi'
import {Button} from "@mui/material";

import MetaMaskFox from "../../images/metamask-fox.svg";
import Coinbase from "../../images/coinbase-wallet.png";
import WalletConnect from "../../images/walletconnect.svg";
import Injected from "../../images/ethereum-icon-purple.svg";

function ConnectWallet() {
    const { address, connector, isConnected } = useAccount()
    const { data: ensAvatar } = useEnsAvatar({ addressOrName: address })
    const { data: ensName } = useEnsName({ address })
    const { connect, connectors, error, isLoading, pendingConnector } =
        useConnect()
    const { disconnect } = useDisconnect()

    function getImage(connectorId) {
        switch (connectorId) {
            case 'metaMask':
                return MetaMaskFox
            case 'coinbaseWallet':
                return Coinbase
            case 'walletConnect':
                return WalletConnect
            default:
                return Injected
        }
    }

    if (isConnected && connector) {
        return (
            <div>
                {connectors.map((connector) => (
                    <Button
                        variant={"outlined"}
                        style={{textTransform: "none", margin: 8}}
                        color={"inherit"}
                        startIcon={<img alt={connector.name} height={24} src={getImage(connector.id)}/>}
                        disabled={true}
                        key={connector.id}
                    >
                        {connector.name}
                        {!connector.ready && ' (unsupported)'}
                        {isLoading &&
                        connector.id === pendingConnector?.id &&
                        ' (connecting)'}
                    </Button>
                ))}
            </div>
        )
    }

    return (
        <div>
            {connectors.map((connector) => (
                <Button
                    variant={"outlined"}
                    style={{textTransform: "none", margin: 8}}
                    color={"inherit"}
                    startIcon={<img alt={connector.name} height={24} src={getImage(connector.id)}/>}
                    disabled={!connector.ready}
                    key={connector.id}
                    onClick={() => connect({ connector })}
                >
                    {connector.name}
                    {!connector.ready && ' (unsupported)'}
                    {isLoading &&
                    connector.id === pendingConnector?.id &&
                    ' (connecting)'}
                </Button>
            ))}

            {error && <div>{error.message}</div>}
        </div>
    )
}

export default ConnectWallet
