import React, {useCallback, useState} from "react";
import {CircularProgress} from "@mui/material";

const ImageWithRetry = ({imageSource}) => {
    const [retryCounter, setRetryCounter] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false)

    const onImageLoaded = useCallback(() => {
        setIsLoaded(true)
    }, []);

    const onImageError = () => {
        if (retryCounter < 10) {
            setRetryCounter(retryCounter + 1)
        }
    }

    const containerStyle = {
        alignItems: 'center',
        display: 'flex',
        height: '400px',
        justifyContent: 'center',
        marginTop: 64,
        marginBottom: 64
    }

    const style = {
        position: 'relative',
        textAlign: 'center',
    }

    return (
        <React.Fragment>
            {!isLoaded && retryCounter < 10 ?
                <div style={containerStyle}>
                    <div style={style}>
                        <CircularProgress/>
                        <p>Loading your certificate from the IPFS...</p>
                        <p><small>(This may take a couple of minutes)</small></p>
                    </div>
                </div>
                : ''
            }
            <img
                key={`image-${retryCounter}`}
                src={imageSource}
                style={{marginTop: 64, marginBottom: 64}}
                width={400}
                onLoad={onImageLoaded}
                onError={onImageError}
                onLoadStart={onImageLoaded}
                alt={"Web3Academy badge"}
            />
        </React.Fragment>
    );
}

export default ImageWithRetry
